@import '../../Styles/settings';

.icon {
  margin-right: 8px;
}

.buttonContentWrapper {
  display: flex;
  align-items: center;
}
