.wrapper {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  &:before {
    display: block;
    content: '';
  }
}

.size-16by9 {
  &:before {
    padding-top: 56.25%;
  }
}

.size-21by9 {
  &:before {
    padding-top: 42.857143%;
  }
}

.size-4by3 {
  &:before {
    padding-top: 75%;
  }
}


