@import '../../Styles/settings';

.iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.span {
  display: inline-block;
  height: 1px;
  width: 1px;
}

.picture {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* for ie 11*/
  flex-shrink: 0;
}
.wrapper {
  position: relative;
}
.playIcon {
  width: 80px;
  height: 80px;
  circle {
    transition: fill 0.3s $main-timing-function;
    fill: $color-brand;
  }
  path {
    transition: fill 0.3s $main-timing-function;
    fill: white;
  }
}

.btn {
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: none;
  padding: 0;
  cursor: pointer;
}

.modal {
  width: calc(100% - 20px);
  max-width: 700px;
  padding: 50px 15px 30px;
  max-height: 90%;
  overflow-y: auto;
  background-color: $color-modal-bg;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.closeBtn {
  position: absolute;
  top: 5px;
  right: 10px;
}

.closeSvg {
  height: 20px;
  width: 20px;
}

@media #{$md-up} {
  .btn {
    circle {
      fill: white;
    }

    path {
      fill: $color-brand;
    }

    &:hover {
      circle {
        fill: $color-brand;
      }
      path {
        fill: white;
      }
    }
  }
}
